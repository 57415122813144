<script lang="ts">
  import type { SvelteHTMLElements } from 'svelte/elements';

  type $$Props = SvelteHTMLElements['div'] & {
    contentRoot?: HTMLDivElement;
  };

  export let contentRoot = undefined as any;

  // NOTE: this component is a prospect to move to @bucketh/ui (as CommonAppLayout)
  /*
  TODO:
    - Add support for mobile / tablet / desktop (/ fold?)
    - Add slot por fab buttons
  */
</script>

<div {...$$restProps} class="h-full flex flex-col bg-surface-container-lowest {$$restProps.class}">
  {#if $$slots.top}
    <div class="shrink-0 flex flex-col relative">
      <slot name="top" />
    </div>
  {/if}
  <div class="flex-1 flex relative overflow-hidden">
    <!-- drawer here ? -->
    <div class="flex-1 flex flex-col overflow-auto" bind:this={contentRoot}>
      <slot name="content" />
    </div>
  </div>
  {#if $$slots.nav}
    <div class="shrink-0">
      <slot name="nav" />
    </div>
  {/if}
</div>
