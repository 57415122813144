import { writable, type Writable } from 'svelte/store';
import type { Link, QueryState, Mutation, Query, QueryResponse, Globals } from '.';

interface QueryResponseRaw<Data> {
  data: Data;
  errors: Globals['error'][];
}

type QueryStateStore<Data> = Writable<QueryState<Data>>

export type LinkHeadersGetter = () => (void | null | undefined | Record<string, string> | Promise<Record<string, string>>);

export interface SimpleLinkOptions {
  url: string;
  getHeaders?: LinkHeadersGetter;
}

type FileData = {
  path: string;
  file: File;
};

function replacer(key: string, value: any) {
  if (value instanceof File) return null;
  return value;
}

export class SimpleLink implements Link {
  url: string;
  getHeaders: LinkHeadersGetter | undefined;

  constructor({ url, getHeaders }: SimpleLinkOptions) {
    this.url = url;
    this.getHeaders = getHeaders;
  }

  private getFilesData(variables: any, currentPath = 'variables'): FileData[] {
    if (!variables) return [];
    return Object.entries(variables).reduce((result, [name, value]) => {
      if (value instanceof File) {
        result.push({
          path: currentPath ? `${currentPath}.${name}` : name,
          file: value,
        });

        return result;
      }

      if (value instanceof Object || value instanceof Array) {
        return result.concat(
          this.getFilesData(value, currentPath ? `${currentPath}.${name}` : name),
        );
      }

      return result;
    }, [] as FileData[]);
  }

  private async fetchJson(query: any, variables: any) {
    const headers = await this.getHeaders?.();

    return fetch(this.url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify({ query, variables }),
    })
  }

  private async fetchMultipart(query: any, variables: any, files: FileData[]) {
    const formData = new FormData();

    const map = files.reduce((acc, { path, file }, index) => {
      // TODO: Optimize "getFilesData" to reuse files by defining multiple variables,
      // e.g., "0" can map to both "variables.images.0" and "variables.thumbnail".
      // Refactor the function to enable a single file to serve multiple variable mappings.
      acc[index] = [path];
      return acc;
    }, {} as Record<string, [path: string]>);

    formData.append('operations', JSON.stringify({ query, variables }, replacer));
    formData.append('map', JSON.stringify(map));
    files.forEach(({ file }, index) => {
      formData.append(`${index}`, file);
    });
    // formData.append('query', query);
    // formData.append('variables', JSON.stringify(variables));

    const headers = await this.getHeaders?.();

    return fetch(this.url, {
      method: 'POST',
      headers: {
        ...headers,
      },
      body: formData,
    });
  }

  async request(
    stateStore: QueryStateStore<any>,
    query: string,
    variables: any,
  ) {
    stateStore.update((current) => ({
      ...current,
      loading: true,
    }));

    const files = this.getFilesData(variables);
    const req = files.length // TODO: add "multipart" config/prop ('json' | 'multipart' | 'dynamic')
      ? this.fetchMultipart(query, variables, files)
      : this.fetchJson(query, variables);

    return req
      .then((res) => res.json())
      .then((resp: QueryResponseRaw<any>) => {
        const parsedResp: QueryResponse<any> = {
          data: resp.data || null,
          error: resp.errors?.[0] ?? null,
        };

        stateStore.set({
          ...parsedResp,
          invalid: false,
          fetched: true,
          loading: false,
          updatedAt: new Date(),
        });

        return parsedResp;
      })
      .catch((reason) => {
        const parsedResp: QueryResponse<any> = {
          data: null,
          error: reason,
        };

        stateStore.set({
          ...parsedResp,
          invalid: false,
          fetched: true,
          loading: false,
          updatedAt: new Date(),
        });

        return parsedResp;
      });
  }

  createQuery(query: string, variables: object | undefined): Query<any> {
    const info: QueryState<any> = {
      invalid: true,
      fetched: false,
      data: null,
      error: null,
      loading: false,
      updatedAt: null,
    };

    const stateStore: QueryStateStore<any> = writable(info);

    const queryStore: Query<any> = {
      ...stateStore,
      refetch: () => this.request(stateStore, query, variables),
    }

    return queryStore;
  }

  createMutation(query: string): Mutation<any, any> {
    const info: QueryState<any> = {
      fetched: false,
      data: null,
      error: null,
      loading: false,
      updatedAt: null,
      invalid: true,
    };

    const stateStore: QueryStateStore<any> = writable(info);

    const mutationStore: Mutation<any, any> = {
      ...stateStore,
      mutate: (variables) => this.request(stateStore, query, variables),
    }

    return mutationStore;
  }
}
