<script lang="ts">
  // @ts-ignore
  import Loadable from 'svelte-loadable';
  import { Route } from 'svelte-navigator';
  import urlTo from '$utils/urlTo';
  import Auth from '$components/Auth.svelte';
  import NotFoundPage from './NotFoundPage.svelte';
  import AppSelector from './AppSelector.svelte';
</script>

<Route path="/">
  <Auth onlyNoSession>
    <Loadable loader={() => import('./SignInPage.svelte')} />
  </Auth>
</Route>

<Route path="/sign-up">
  <Auth onlyNoSession>
    <Loadable loader={() => import('./SignUpPage.svelte')} />
  </Auth>
</Route>

<Route path="/email-verification">
  <Auth>
    <Loadable loader={() => import('./EmailVerificationPage.svelte')} />
  </Auth>
</Route>

<Route path="/apps">
  <Auth>
    <AppSelector />
  </Auth>
</Route>

<Route path="/resident/*">
  <Auth role="resident">
    <Loadable loader={() => import('./resident/ResidentRoutes.svelte')} />
  </Auth>
</Route>

<Route path="/security/*">
  <Auth role="security">
    <Loadable loader={() => import('./security/SecurityRoutes.svelte')} />
  </Auth>
</Route>

<Route path="/admin/*">
  <Auth role="admin">
    <Loadable loader={() => import('./admin/AdminRoutes.svelte')} />
  </Auth>
</Route>

<Route path={urlTo.settings.id}>
  <Auth>
    <Loadable loader={() => import('./SettingsPage.svelte')} />
  </Auth>
</Route>

<Route>
  <NotFoundPage />
</Route>
