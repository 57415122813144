import type { UseQueryOptions } from '../@bucketh-gql/types';
import { useQuery } from '../@bucketh-gql/useQuery';
import type { MeQuery, MeQueryVariables } from './_generated';
import query from './useMeQuery.graphql?raw';

type Data = MeQuery;
type Variables = MeQueryVariables;
type Options = UseQueryOptions<Data, Variables> | undefined;

export default function useMeQuery(options: Options = undefined) {
  return useQuery<Data, Variables>(query, options);
}
