export default function createPendingPromise<T = void>() {
  let resolve: T extends void ? () => void : (value: T) => void = null as any;
  let reject: (reason?: any) => void = null as any;
  const promise = new Promise<T>((res, rej) => {
    resolve = res as any;
    reject = rej;
  });

  return [promise, resolve, reject] as [promise: Promise<T>, resolve: (value: T) => void, reject: (reason?: any) => void];
}
