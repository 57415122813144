<script lang="ts">
  import { fade } from 'svelte/transition';
  import { useNavigate } from 'svelte-navigator';
  import type { MemberRoleEnum } from '$gql/_generated';
  import useMeQuery from '$gql/useMeQuery';
  import urlTo from '$utils/urlTo';

  export let onlyNoSession = false;
  export let role: MemberRoleEnum | null = null;

  const navigate = useNavigate();
  const meQuery = useMeQuery();

  async function checkForAuth() {
    if ($meQuery.loading || !$meQuery.fetched) return;
    const session = $meQuery.data?.me;

    if (session && !session.emailVerified) {
      return navigate(urlTo.emailVerification(), { replace: true });
    }
    if (!session && !onlyNoSession) {
      return navigate(urlTo.signIn(), { replace: true });
    }
    if (session && onlyNoSession) {
      return navigate(urlTo.appSelector(), { replace: true });
    }

    // if ($meQuery.loading || !$meQuery.fetched) return;
    // const hasSession = !!$meQuery.data?.me;

    // if (!hasSession && !onlyNoSession) {
    //   // go to login if user visits an app page and is not authenticated
    //   navigate(urlTo.signIn(), { replace: true });
    // } else if (hasSession && onlyNoSession) {
    //   // go to app-selector if user visits signin page and is authenticated
    //   navigate(urlTo.appSelector(), { replace: true });
    // } else if (firebaseUser) {
    //   console.log('redirect to firebase auth');
    //   navigate(urlTo.firebaseAuth(), { replace: true });
    // }
  }

  function checkForRole() {
    if (!$meQuery.data) return;
    if (!role) return;

    const hasRole = $meQuery.data.me.memberships.some(({ roles }) =>
      !!role // only ts validation
      && roles.includes(role)
    );

    if (!hasRole) {
      navigate(urlTo.appSelector());
    }
  }

  $: $meQuery.data?.me, checkForAuth();
  $: $meQuery.data?.me, checkForRole();
</script>

{#if $meQuery.loading || !$meQuery.fetched}
  <!-- AUTH LOADING -->
{:else}
  <div class="h-full overflow-y-auto" in:fade={{ duration: 100 }} out:fade={{ duration: 100 }} >
    <slot />
  </div>
{/if}
