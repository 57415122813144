<script lang="ts">
  import { SimpleCache, type Cache, type Link } from '.';
  import { setGqlContext } from './context';

  export let link: Link;
  export let cache: Cache = new SimpleCache();

  setGqlContext({ link, cache });
</script>

<slot />
