<!-- OfflineDetector.svelte -->
<script lang="ts">
  import { onMount } from 'svelte';
  import WifiOffIcon from '@bucketh/icons/WifiOffIcon.svelte';

  let isOnline = navigator.onLine;

  onMount(() => {
    const updateOnlineStatus = () => {
      isOnline = navigator.onLine;
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  });
</script>

{#if isOnline}
  <slot />
{:else}
  <div class="w-full h-full flex flex-col items-center pt-16">
    <WifiOffIcon class="w-36 h-36 text-on-surface-variant" />
    <h1 class="text-title-large">
      Comprueba tu conexión
    </h1>
  </div>
{/if}
