<script lang="ts">
  type $$Props = {
    class?: string;
  };
</script>

<div class="text-primary-fixed-dim {$$props.class || ''}" />

<style lang="postcss">
  div {
    width: 50px;
    aspect-ratio: 1;
    --c: no-repeat radial-gradient(farthest-side, currentcolor 92%, #0000);
    background: 
      var(--c) 50%  0, 
      var(--c) 50%  100%, 
      var(--c) 100% 50%, 
      var(--c) 0    50%;
    background-size: 10px 10px;
    animation: l18 1s infinite;
    position: relative;
  }

  div::before {    
    content: "";
    position: absolute;
    inset: 0;
    margin: 3px;
    background: repeating-conic-gradient(#0000 0 35deg, currentcolor 0 90deg);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
    border-radius: 50%;
  }

  @keyframes l18 { 
    100% { transform: rotate(.5turn); }
  }
</style>
