<script lang="ts">
  import { fade } from 'svelte/transition';
  import loading from '$stores/loading';
  import useMeQuery from '$gql/useMeQuery';
  import SpinLoading from './SpinLoading.svelte';

  loading.useQueryWaiter(
    useMeQuery({ lazy: true }),
    { fadeOutDelay: 300 },
  );
</script>

{#if $loading}
  <div
    in:fade={{ duration: 100 }}
    out:fade={{ duration: 100 }}
    class="fixed top-0 left-0 right-0 bottom-0 bg-scrim bg-opacity-60 flex items-center justify-center z-50"
  >
    <SpinLoading />
  </div>
{/if}
