import { writable } from 'svelte/store';

export interface Config {
  theme: 'light' | 'dark';
  selectedDwelling: number;
  selectedCommunity: number;
}

const defaultConfig: Config = {
  theme: 'light',
  selectedDwelling: 0,
  selectedCommunity: 0,
};

function getInitialConfig(): Config { 
  const config = localStorage.getItem('config');
  try {
    if (config) {
      return {
        ...defaultConfig,
        ...JSON.parse(config)
      };
    }
  } catch {}
  return defaultConfig;
}

const config = {
  ...writable<Config>(getInitialConfig()),
  reset: () => {
    localStorage.removeItem('config');
    config.set(getInitialConfig());
  },
  toggleTheme: () => {
    config.update((c) => ({ ...c, theme: c.theme === 'light' ? 'dark' : 'light' }));
  },
};

export default config;

// write state to local storage
config.subscribe((value) => {
  localStorage.setItem('config', JSON.stringify(value));
});
