import type { Writable } from 'svelte/store';
import type { Cache, CacheVariablesFilter, CacheKey, QueryState } from '.';

function equal(a: any, b: any): boolean {
  if (a === b) return true;

  if (typeof a !== 'object' || typeof b !== 'object' || !a || !b) {
    return false;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) return false;

  for (const key of keysA) {
    if (!keysB.includes(key) || !equal(a[key], b[key])) {
      return false;
    }
  }

  return true;
}

// TODO: fix types

export class SimpleCache implements Cache {
  items: Array<{ key: CacheKey, store: Writable<QueryState<any>> }> = [];

  get(key: CacheKey) {
    return this.items.find(i => equal(i.key, key))?.store ?? null;
  }

  put(key: CacheKey, store: Writable<any>) {
    let cachedStore = this.get(key);
    if (!cachedStore) {
      this.items.push({ key, store });
      return store;
    }

    return cachedStore;
  }

  search(query: string, filter: CacheVariablesFilter<any> | undefined = undefined) {
    const result = this.items.filter((item) => {
      if (item.key[0] !== query) return false;
      if (filter && filter instanceof Function) return filter(item.key[1]);
      if (filter) return equal(item.key, filter);

      return true;
    }).map(({ store }) => store);

    return result;
  }

  clear() {
    this.items = [];
  }
}
