import { getContext, setContext } from 'svelte';
import type { Config } from '.';

const KEY = 'bucketh/gql';

export function getGqlContext() {
  return getContext(KEY) as Config;
}

export function setGqlContext(client: Config) {
  setContext(KEY, client);
}
