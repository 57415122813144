<script lang="ts">
  import { UIBucketh } from '@bucketh/ui';
  import { Router } from 'svelte-navigator';
  import GqlProvider from './providers/GqlProvider.svelte';
  import config from './stores/config';
  import RootRoutes from './pages/RootRoutes.svelte';
  import GlobalLoading from './components/GlobalLoading.svelte';
  import OfflineDetector from './components/OfflineDetector.svelte';

  (window as any).toggleTheme = () => {
    $config.theme = $config.theme === 'light' ? 'dark' : 'light';
  };

  console.log('Available config');
  console.log('- call "toggleTheme()" to toggle between light and dark theme');
  // Considerar usar este azúl como primary color #4477c5
</script>

<GqlProvider>
  <UIBucketh theme={$config.theme} class="bg-surface-container-lowest text-on-surface">
    <OfflineDetector>
      <Router primary={false}>
        <RootRoutes />
        <GlobalLoading />
      </Router>
    </OfflineDetector>
  </UIBucketh>
</GqlProvider>
