import { get, writable, type Readable } from 'svelte/store';
import type { QueryState } from '@bucketh/gql';
import { onDestroy } from 'svelte';

const DELAY = 300;

const loading = {
  ...writable(false),
  useQueryWaiter: <QueryStore extends Readable<QueryState<any>>>(
    query: QueryStore,
    config: { fadeOutDelay: number } | null = null,
  ) => {
    const unsubscribe = query.subscribe(($state) => {
      if (get(loading) === $state.loading) return;

      if ($state.loading) {
        loading.set(true);
      } else {
        setTimeout(() => {
          loading.set(false);
        }, config?.fadeOutDelay || DELAY);
      }
    });
    onDestroy(unsubscribe);

    return query;
  },
  waitFor: (promise: Promise<any>) => {
    loading.set(true);
    promise.finally(() => loading.set(false));
    return promise;
  },
  delay: (time: number = DELAY) => new Promise<void>((resolve) => {
    loading.set(true);
    setTimeout(() => {
      loading.set(false);
      resolve();
    }, time);
  }),
  // @ts-ignore
  start: () => loading.set(true),
  stop: () => loading.set(false),
};

export default loading;
