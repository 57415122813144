<script lang="ts">
  import type { SvelteHTMLElements } from 'svelte/elements';

  type $$Props = SvelteHTMLElements['svg'];
</script>

<svg viewBox="0 -960 960 960" width="24" height="24" fill="currentColor" {...$$props}>
  <path d="M 89.308 -518.311 L 41.308 -582.311 L 481.308 -918.311 L 641.308 -796.311 L 641.308 -878.311 L 761.308 -878.311 L 761.308 -704.311 L 921.308 -582.311 L 873.308 -518.311 L 481.308 -817.311 L 89.308 -518.311 Z M 481.308 -358.311 Z"/>
  <path d="M 700 -100 L 760 -100 L 760 -40 L 700 -40 L 700 -100 Z M 700 -220 L 760 -220 L 760 -160 L 700 -160 L 700 -220 Z M 640 -160 L 700 -160 L 700 -100 L 640 -100 L 640 -160 Z M 580 -100 L 640 -100 L 640 -40 L 580 -40 L 580 -100 Z M 520 -160 L 580 -160 L 580 -100 L 520 -100 L 520 -160 Z M 640 -280 L 700 -280 L 700 -220 L 640 -220 L 640 -280 Z M 580 -220 L 640 -220 L 640 -160 L 580 -160 L 580 -220 Z M 520 -280 L 580 -280 L 580 -220 L 520 -220 L 520 -280 Z M 760 -600 L 760 -360 L 520 -360 L 520 -600 L 760 -600 Z M 440 -280 L 440 -40 L 200 -40 L 200 -280 L 440 -280 Z M 440 -600 L 440 -360 L 200 -360 L 200 -600 L 440 -600 Z M 380 -100 L 380 -220 L 260 -220 L 260 -100 L 380 -100 Z M 380 -420 L 380 -540 L 260 -540 L 260 -420 L 380 -420 Z M 700 -420 L 700 -540 L 580 -540 L 580 -420 L 700 -420 Z"/>
</svg>
