import '@bucketh/gql';
import * as Sentry from '@sentry/svelte';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import duration from 'dayjs/plugin/duration';
import updateLocale from 'dayjs/plugin/updateLocale';

declare module '@bucketh/gql' {
  interface Globals {
    error: {
      message: string;
    };
  }
}

// #region Dayjs
dayjs.extend(minMax);
dayjs.extend(duration);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  months: [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre',
    'Octubre', 'Noviembre', 'Diciembre',
  ],
  monthsShort: [
    'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic',
  ],
});
(window as any).dayjs = dayjs;

// #endregion

// #region Sentry
if (import.meta.env.MODE === 'production') {
  console.log('Sentry initialized');
  
  Sentry.init({
    dsn: 'https://bf2b911a7782c244218ae83c6bfccb67@o4507098930020352.ingest.us.sentry.io/4507098942930944',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
// #endregion 

// #region Firebase
import { initializeApp, type FirebaseOptions } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';

if (import.meta.env.VITE_FIREBASE_EMULATOR) {
  console.log('run emulator');
  
  initializeApp({
    projectId: 'demo-privalix-local',
    apiKey: 'demo-api-key',
  });
  const firebaseAuth = getAuth();
  connectAuthEmulator(firebaseAuth, 'http://127.0.0.1:9099');
} else {
  const firebaseConfigStaging: FirebaseOptions = {
    apiKey: "AIzaSyBGgw90sbTo0OhKpjd0fOEodkzEIDGtVWo",
    authDomain: "privalix-staging-e73b7.firebaseapp.com",
    projectId: "privalix-staging-e73b7",
    storageBucket: "privalix-staging-e73b7.appspot.com",
    messagingSenderId: "537652641244",
    appId: "1:537652641244:web:3cdde03ca61788199c6319",
    measurementId: "G-LW8C5PZ3XN"
  };
  
  const firebaseConfigProd: FirebaseOptions = {
    apiKey: 'AIzaSyBiRioHDjJWkvnYcrXO8TmkwoUma2yy-ss',
    authDomain: 'privalix-app.firebaseapp.com',
    projectId: 'privalix-app',
    storageBucket: 'privalix-app.appspot.com',
    messagingSenderId: '411032933489',
    appId: '1:411032933489:web:7f21e18646fba1049377d0',
    measurementId: 'G-08ZCYH4V6L'
  };
  
  initializeApp(
    import.meta.env.MODE === 'production' ? firebaseConfigProd : firebaseConfigStaging,
  );
}
// #endregion 
