import { useParams } from 'svelte-navigator';

type Param = string | number;

const urlTo = {
  signIn: () =>
    `/`,
  signUp: () =>
    `/sign-up`,
  appSelector: () =>
    `/apps`,
  emailVerification: () => 
    `/email-verification`,

  // Resident pages
  residentHome: () =>
    `/resident`,
  residentPayments: () =>
    `/resident/payments`,
  residentPayment: (id: Param) =>
    `/resident/payments/${id}`,
  residentAccessVisitors: () =>
    `/resident/access/visitors`,
  residentVisitorCode: (visitorId: Param) =>
    `/resident/access/visitors/${visitorId}/code`,
  residentAccess: () => 
    `/resident/access`,
  residentNewVisitor: () =>
    `/resident/access/visitors/create`,
  residentAccessHistory: () =>
    `/resident/access/history`,
  residentAmenities: () =>
    `/resident/amenities`,
  residentAmenitiesAreas: () =>
    `/resident/amenities/areas`,
  resident: {
    amenity: ({
      amenityId, period, refresh, newBooking
    }: {
      amenityId: Param, period?: string, refresh?: boolean, newBooking?: number,
    }) => {
      const params = new URLSearchParams();
      if (period) params.set('period', period);
      if (refresh) params.set('refresh', 'true');
      if (newBooking) params.set('newBooking', newBooking.toString());

      return `/resident/amenities/areas/${amenityId}?${params}`;
    },
    amenityBookings: ({
      amenityId, date,
    }: {
      amenityId: Param, date: string,
    }) =>
      `/resident/amenities/areas/${amenityId}/bookings?date=${date}`,
    booking: (id: Param) =>
      `/resident/amenities/bookings/${id}`,
    // amenityAreasTab: () =>
    //   `/resident/amenities/areas`,
    // amenityBookingsTab: () =>
    //   `/resident/amenities/bookings`,
    notifications: () =>
      '/resident/notifications',
  },
  admin: {
    home: Object.assign(() => '/admin', {
      id: '/',
    }),
    members: Object.assign(() => '/admin/members', {
      id: '/members',
    }),
    member: Object.assign((id: Param) => `/admin/members/${id}`, {
      id: '/members/:userId',
      useParams: () => useParams<{ userId: string }>(),
    }),
    newMember: Object.assign(() => `/admin/members/new`, {
      id: '/members/new',
    }),
    dwellings: Object.assign(() => '/admin/dwellings', {
      id: '/dwellings',
    }),
    dwelling: Object.assign((id: Param) => `/admin/dwellings/${id}`, {
      id: '/dwellings/:dwellingId',
      useParams: () => useParams<{ dwellingId: string }>(),
    }),
    dwellingPayments: Object.assign((
      { dwellingId, tab }: { dwellingId: Param, tab: 'pending' | 'approved' },
    ) => `/admin/dwellings/${dwellingId}/payments/${tab}`, {
      id: '/dwellings/:dwellingId/payments/*tab',
      useParams: () => useParams<{ dwellingId: string, tab: 'pending' | 'approved' }>(),
    }),
    newDwelling: Object.assign(() => `/admin/dwellings/new`, {
      id: '/dwellings/new',
    }),
    dwellingPayment: Object.assign((id: number) => `/admin/payments/${id}`, {
      id: '/payments/:paymentId',
      useParams: () => useParams<{ paymentId: string }>(),
    }),
  },
  settings: Object.assign(() => '/settings', {
    id: '/settings',
  }),

  // Security pages
  securityHome: () =>
    `/security`,
};

export default urlTo;
