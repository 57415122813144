import { derived } from 'svelte/store';
import type { CacheKey, Query, UseQuery, UseQueryOptions } from '.';
import { getGqlContext } from './context';

function getOrCreateQuery(query: string, options: UseQueryOptions<any, object> | undefined) {
  const { cache, link } = getGqlContext();
  const cachePolicy = options?.cachePolicy ?? 'cache-and-network';

  if (cachePolicy === 'network-only') {
    const newQuery = link.createQuery(query, options?.variables);
    return newQuery;
  }

  const queryKey: CacheKey = options?.variables ? [query, options.variables] : [query];
  const cachedQuery = cache.get(queryKey) as Query<any>;
  if (cachedQuery) return cachedQuery;

  const newQuery = link.createQuery(query, options?.variables);
  cache.put(queryKey, newQuery);

  return newQuery;
}

export const useQuery: UseQuery = (queryString, options) => {
  const query = getOrCreateQuery(queryString, options as UseQueryOptions<any, object>);
  const lazy = options?.lazy ?? false;

  // // #region micro optimization: could prevent a re-render
  // const queryData = get(query);
  // if (queryData.invalid && !queryData.loading) {
  //   query.refetch();
  // }
  // // #endregion

  const derivedQuery = {
    ...query,
    ...derived(query, $query => {
      if ($query.invalid && !$query.loading && !lazy) {        
        query.refetch();
        return {
          ...$query,
          loading: true, // micro optimization: could prevent a re-render
        };
      }

      return $query;
    }),
  };

  return derivedQuery;
};
