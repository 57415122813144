<script lang="ts">
  import { Link } from 'svelte-navigator';
  import SentimentDissatisfiedIcon from '@bucketh/icons/SentimentDissatisfiedIcon.svelte'

  type $$Props = {
    class?: string;
  };
</script>

<div class="w-full h-full flex flex-col items-center justify-center gap {$$props.class || ''}">
  <SentimentDissatisfiedIcon class="w-24 h-24" />
  <div class="text-display-small py-2">
    Upss
  </div>
  <div class="text-body-large">
    Página no encontrada
  </div>
  <Link to="/" class="text-primary">
    Ir al inicio
  </Link>
</div>
