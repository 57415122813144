<script lang="ts">
  import { getAuth, onAuthStateChanged } from 'firebase/auth';
  import { BuckethGqlContext, SimpleLink } from '@bucketh/gql';
  import createPendingPromise from '$utils/createPendingPromise';

  const [firebaseLoading, resolveFirebaseLoading] = createPendingPromise();

  onAuthStateChanged(getAuth(), () => {
    resolveFirebaseLoading();
  });

  const link = new SimpleLink({
    url: import.meta.env.VITE_API_URL,
    getHeaders: async () => {
      await firebaseLoading;
      const auth = getAuth();
      const firebaseAuth = auth.currentUser?.getIdToken ? await auth.currentUser.getIdToken() : '';

      return {
        'privalix-auth': localStorage.getItem('auth') || '',
        'firebase-auth': firebaseAuth,
      };
    },
  });
</script>

<BuckethGqlContext {link}>
  <slot />
</BuckethGqlContext>
