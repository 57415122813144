<script lang="ts">
  import { Link, useNavigate } from 'svelte-navigator';
  import HomeIcon from '@bucketh/icons/HomeIcon.svelte';
  import ShieldIcon from '@bucketh/icons/ShieldIcon.svelte';
  import SettingsIcon from '@bucketh/icons/SettingsIcon.svelte';
  import ChevronRightIcon from '@bucketh/icons/ChevronRightIcon.svelte';
  import BarChart4BarsIcon from '@bucketh/icons/BarChart_4BarsIcon.svelte';
  import { Card, ListItem, TopAppBar } from '@bucketh/ui';
  import urlTo from '$utils/urlTo';
  import useMeQuery from '$gql/useMeQuery';
  import PrivalixIcon from '$components/PrivalixIcon.svelte';
  import CommonLayout from '$ui/CommonLayout.svelte';

  const navigate = useNavigate();
  const meQuery = useMeQuery();

  $: data = $meQuery.data?.me;

  function getAvailableApps() {
    const allRoles = data?.memberships.flatMap(({ roles }) => roles) || [];

    return [...new Set(allRoles.sort())];
  }

  $: availableApps = data ? getAvailableApps() : [];

  // redirect if only one app is available
  $: if (!$meQuery.loading && availableApps.length == 1) {
    const [app] = availableApps;

    if (app === 'admin') {
      navigate(urlTo.admin.home(), { replace: true });
    } else if (app === 'resident') {
      navigate(urlTo.residentHome(), { replace: true });
    } else if (app === 'security') {
      navigate(urlTo.securityHome(), { replace: true });
    } else {
      throw new Error(`Unexpected App type "${app}"`);
    }
  }
</script>

{#if data}
  {#if availableApps.length}
    <CommonLayout>
      <TopAppBar elevated slot="top">
        <div class="leading">
          <PrivalixIcon />
        </div>
        <div class="title">
          Apps de Privalix
        </div>
      </TopAppBar>
      <div slot="content" class="p-4 flex flex-col gap-4">
        <div>
          Selecciona la App que necesitas usar
        </div>
        <Card class="py-2">
          {#each availableApps as app}
            {#if app === 'admin'}
              <Link to={urlTo.admin.home()}>
                <ListItem>
                  <BarChart4BarsIcon slot="leading" />
                  <b>Administración</b>
                  <ChevronRightIcon slot="trailing" />
                </ListItem>
              </Link>
            {:else if app === 'resident'}
              <Link to={urlTo.residentHome()}>
                <ListItem>
                  <HomeIcon slot="leading" />
                  <b>Residente</b>
                  <ChevronRightIcon slot="trailing" />
                </ListItem>
              </Link>
            {:else if app === 'security'}
              <Link to={urlTo.securityHome()}>
                <ListItem>
                  <ShieldIcon slot="leading" />
                  <b>Agente de seguridad</b>
                  <ChevronRightIcon slot="trailing" />
                </ListItem>
              </Link>
            {/if}
          {/each}
        </Card>
      </div>
    </CommonLayout>
  {:else}
    <CommonLayout>
      <TopAppBar elevated slot="top">
        <div class="leading">
          <PrivalixIcon />
        </div>
        <div class="title">
          Privalix
        </div>
        <Link to={urlTo.settings()} class="trailing">
          <SettingsIcon />
        </Link>
      </TopAppBar>
      <div slot="content" class="h-full p-4 flex flex-col gap-4 text-on-surface-variant text-body-large">
        <div class="text-title-large">
          Hola {data.firstName}
        </div>
        <p>
          Para empezar a usar PrivaliX, necesitas ser agregado por la administración de tu colonia. Ponte en contacto con ellos para obtener acceso
        </p>
        <p>
          Tu email es
          <b class="text-on-surface">
            {data.email}
          </b>
        </p>
      </div>
    </CommonLayout>
  {/if}
{/if}
